<template>
  <custom-popup
    class="new-remark"
    :show="show"
    :title="isEdit ? '编辑备注' : '添加备注'"
    @on-close="$emit('on-close')"
  >
    <div class="pop-content">
      <field-item v-if="form.id !== 'remarks'" label="检查点">
        <el-select
          v-model="form.checkName"
          readonly
          class="check-category"
          size="small"
          placeholder="请选择检查点"
          @focus="isShowCategory = true"
        ></el-select>
      </field-item>
      <field-item label="备注描述" required>
        <el-input
          v-model="form.description"
          placeholder="请输入"
          type="textarea"
          :rows="3"
          @blur="handleInputBlur"
        />
      </field-item>
      <field-item v-if="form.id !== 'remarks'" label="备注照片">
        <imageUploader v-model="form.pictures" :max-count="10"></imageUploader>
      </field-item>
    </div>
    <div class="btn-wrapper space-between">
      <van-button
        :loading="loading"
        :disabled="loading"
        class="btn"
        size="large"
        color="#055C9D"
        type="primary"
        @click="handleOk"
      >
        {{ isEdit ? "确定修改" : "确定添加" }}
      </van-button>
    </div>
    <van-popup
      v-model="isShowCategory"
      round
      position="bottom"
      get-container="body"
    >
      <van-cascader
        v-model="cascaderValue"
        active-color="#055C9D"
        title="检查点"
        :field-names="fieldNames"
        :options="options"
        @close="isShowCategory = false"
        @finish="onFinish"
      />
    </van-popup>
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import FieldItem from "@/components/Common/FieldItem.vue";
import {mapActions, mapGetters} from "vuex";
import {getRandomId, imgStrToList} from "@/utils/util";
import {axios} from "@/utils/request";
import {postAction, putAction} from "@/api/manage";
import {cloneDeep, debounce} from "lodash";
import imageUploader from "@/components/Common/ImageUploader.vue";


export default {
  name: "NewRemarkPopup",
  components: {imageUploader, FieldItem, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    defaultCheckItemObj: {
      type: Object,
      default() {
        return {}
      },
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        checkName: "",
        description: "",
        pictures: [],
        articleCheckPointId: ''
      },
      isEdit: false,
      isShowCategory: false,
      cascaderValue: "",
      options: [],
      fieldNames: {
        text: "itemName",
        value: "itemName",
        children: "children",
      },
      selectedOptions: [],
      selectedIndex: [],
      obj: {},
      loading: false,
      checkData: {},
      checkPointList: [],
      customCheckPointList: []
    };
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    init(obj) {
      this.obj = obj;
      this.checkData = obj;
      this.cascaderValue = "";
      this.selectedIndex = [];
      this.isShowCategory = false;
      if (obj) {
        this.form = {
          // checkName: obj.inspectItemName + "-" + obj.checkPointName,
          checkName: obj.checkPointName,
          articleCheckPointId: obj.articleCheckPointId,
          inspectItemId: obj.inspectItemId,
          inspectItemName: obj.inspectItemName,
          checkPointId: obj.checkPointId,
          checkPointName: obj.checkPointName,
          description: obj.description,
          pictures: imgStrToList(obj.pictures),
          id: obj.id,
        };
        this.isEdit = true;
      } else {
        this.form = {
          id: getRandomId(),
          articleCheckPointId: null,
          checkName: "",
          inspectItemId: null,
          inspectItemName: null,
          checkPointId: null,
          checkPointName: null,
          description: "",
          pictures: [],
        };
        this.isEdit = false;
      }
      this.getCheckPointData()
      const checkPointOptions = this.checkPointList.map((i) => {
        // i.itemName = i.label + "-" + i.pointsName;
        i.itemName = i.pointsName;
        return i;
      });
      const customCheckPointListOptions = this.customCheckPointList.map((i) => {
        // i.itemName = i.inspectionItemName + "-" + i.checkPointName;
        i.itemName = i.checkPointName;
        i.custom = 1
        return i;
      });
      this.options = checkPointOptions.concat(customCheckPointListOptions)
      this.options.push({
        itemName: '无',
        checkPointsId: ''
      })
      if (this.defaultCheckItemObj?.id && !obj) {
        this.onFinish({selectedOptions: [this.defaultCheckItemObj]})
      } else if(obj && this.form.inspectItemName) {
        this.cascaderValue = this.form.inspectItemName + '-' + this.form.checkPointName
      } else {
        this.onFinish({
          selectedOptions: [
            {
              itemName: '无',
              checkPointsId: ''
            }
          ]
        })
      }
    },
    handleInputBlur() {
      window.scroll(0, 0);
    },
    isFormValid() {
      if (!this.form.checkName) {
        this.Toast.fail("请选择检查点");
        return false
      }
      // if (!this.form.description && !this.form.pictures.length) {
      //   this.Toast.fail("请输入备注描述或上传相关图片");
      //   return false
      // }
      if (!this.form.description) {
        this.Toast.fail("请输入备注描述");
        return false
      }
      if (this.form.pictures.find((item) => !item.url)) {
        this.Toast.fail("请等待图片上传完成");
        return false;
      }
      return true
    },
    getSubmitData() {
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      const formData = {
        ...this.form,
        pictures: this.form.pictures.map(item => item.url).join(',')
      }
      if(this.form.id === 'remarks') {
        article.remarks = this.form.description
      } else {
        if (this.isEdit) {
          const index = article.inspectionList.remarkList.findIndex(item => item.id === this.form.id)
          if (~index) {
            article.inspectionList.remarkList[index] = formData
          }
        } else {
          article.inspectionList.remarkList.push(formData)
        }
      }
      return taskInfo
    },
    handleOk: debounce(async function () {
      const valid = this.isFormValid()
      if (valid) {
        const data = this.getSubmitData()
        this.loading = true
        const res = await this.saveTaskInfo(data)
        this.loading = false
        if (res.success) {
          this.$emit('on-ok')
        }
      }
    }, 500),
    onFinish({selectedOptions}) {
      this.isShowCategory = false;
      const item = selectedOptions[0]
      if (item.custom === 1) {
        // this.form.checkName = item.inspectionItemName + '-' + item.checkPointName
        this.form.checkName = item.checkPointName
        this.form.articleCheckPointId = item.id
        this.form.inspectItemId = item.id
        this.form.inspectItemName = item.inspectionItemName
        this.form.checkPointId = item.id
        this.form.checkPointName = item.checkPointName
        // this.cascaderValue = this.form.inspectItemName + '-' + this.form.checkPointName
        this.cascaderValue = this.form.checkPointName
      } else if (item.itemName !== '无') {
        // this.form.checkName = item.label + '-' + item.pointsName
        this.form.checkName = item.pointsName
        this.form.articleCheckPointId = item.id
        this.form.inspectItemId = item.inspectionItemId
        this.form.inspectItemName = item.label
        this.form.checkPointId = item.checkPointsId
        this.form.checkPointName = item.pointsName
        // this.cascaderValue = this.form.inspectItemName + '-' + this.form.checkPointName
        this.cascaderValue = this.form.checkPointName
      } else if (item.itemName === '无') {
        this.form.checkName = '无'
        this.form.inspectItemId = ''
        this.form.inspectItemName = ''
        this.form.checkPointId = ''
        this.form.checkPointName = '无'
        this.cascaderValue = '无'
      }
    },
    getCheckPointData() {
      const taskInfo = cloneDeep(this.taskInfo)
      const orderList = taskInfo?.orderList || []
      const order = orderList.find(item => item.id === this.orderId)
      const articleNoList = order?.articleNoList || []
      const article = articleNoList.find(item => item.id === this.articleId)
      this.checkPointList = article.inspectionList?.checkPointList || []
      this.customCheckPointList = article.inspectionList?.customCheckPointList || []
    },
  },
};
</script>

<style scoped lang="less">
.new-remark::v-deep {
  .pop-content {
    min-height: 45vh;
  }

  .check-category {
    width: 100%;
  }

  .btn-wrapper {
    margin-top: 20px;

    .btn {
      width: 100%;
    }
  }
}
</style>
