<template>
  <div class="check-form">
    <avue-form
      ref="form"
      v-if="visible"
      v-model="dataValue"
      :option="formOption"
    />
  </div>
</template>

<script>
import {imgStrToList} from "@/utils/util";

export default {
  name: "CheckForm",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataValue: {},
      formOption: {},
      listenDataValue: false,
    }
  },
  watch: {
    dataValue: {
      deep: true,
      handler() {
        if (this.listenDataValue)  {
          this.$emit('onChange')
        }
      }
    }
  },
  methods: {
    startListenDataValue() {
      setTimeout(() => this.listenDataValue = true, 200)
    },
    stopListenDataValue() {
      this.listenDataValue = false
    },
    setForm(checkItem) {
      const formOption = JSON.parse(checkItem.form)
      formOption.column.forEach(item => {
        if ((item.type === 'textarea' || item.type === 'input') && !item.component) {
          item.component = 'customTextarea'
        }
        if (['checkResult', 'isRead'].includes(item.dataProp)) {
          this.initRequiredRules(item)
        }
      })
      this.initBarcodeScannerValidator(formOption)
      this.initFileUploadValidator(formOption)
      this.initImgUploadValidator(formOption)
      this.initFormDisabled(formOption)
      this.formOption = formOption
      if (this.readonly) {
        this.formOption.disabled = true
      }
      this.dataValue = checkItem.dataValue ? JSON.parse(checkItem.dataValue) : {}
    },
    initRequiredRules(item) {
      item.required = false
      // const rule = {message: `${item.title}必须填写`, required: true}
      const rule = {message: ``, required: false}
      if (!item.rules?.length) {
        item.rules = [rule]
      } else if (Array.isArray(item.rules)) {
        let i = item.rules.find(i => i.required === true)
        if (i) {
          i.required = false
        } else {
          item.rules.push(rule)
        }
      }
    },
    initFormDisabled(formOption) {
      const column = formOption?.column || []
      column.filter(item => !item.component).forEach(item => {
        item.disabled = true
      })
    },
    initBarcodeScannerValidator(formOption) {
      const column = formOption?.column || []
      column.filter(item => item.component === 'barcodeScanner').forEach(item => {
        item.rules = [
          {
            validator: (rule, value, callback) => {
              value = value || '{}'
              let data = JSON.parse(value)
              const minCount = data.minCount
              const barcodeList = data.barcodeList || []
              if (barcodeList.length < minCount) {
                callback(`扫码数量不能小于${minCount}个`)
              } else {
                callback()
              }
            },
            trigger: ["change", "blur"]
          }
        ]
      })
    },
    initFileUploadValidator(formOption) {
      const column = formOption?.column || []
      column.filter(item => item.component === 'fileUploader').forEach(item => {
        item.rules = [
          {
            validator: (rule, value, callback) => {
              callback()
            }
          }
        ]
      })
    },
    initImgUploadValidator(formOption) {
      const column = formOption?.column || []
      column.filter(item => item.component === 'imgUploader').forEach(item => {
        item.rules = [
          {
            validator: (rule, value, callback) => {
              value = value || '{}'
              const data = JSON.parse(value)
              const limit = data.limit
              const imgList = imgStrToList(data.imgList)
              if ((limit || limit !== 0) && (imgList?.length || 0) < parseInt(limit)) {
                callback(`图片数量不能小于${limit}张`)
              } else {
                callback()
              }
            },
            trigger: "change"
          }
        ]
      })
    },
    async getData() {
      return await this.$refs.form.getData()
    },
    validate(func) {
      return this.$refs.form.validate(func)
    },
  },
}
</script>

<style scoped lang="less">
.check-form {
  ::v-deep {
    .el-form-item__label {
      font-size: 12px;
      color: #AAB3BA;
      position: relative;
    }

    .el-upload {
      &.el-upload--text {
        .el-button--primary {
          background: #FFFFFF;
          border: 1px solid #055C9D;
          border-radius: 4px;
          font-size: 14px;
          color: #055C9D;

          .el-icon-upload {
            display: none;
          }
        }
      }

      &.el-upload--picture-card {
        width: 70px;
        height: 70px;
        background: #F9F9F9;
        border-radius: 2px;
        position: relative;

        .el-icon-plus {
          position: absolute;
          top: 21px;
          right: 21px;
        }
      }
    }

    .el-radio-group {
      .el-radio {
        margin-right: 7px;

        .el-radio__label {
          padding-left: 5px;
          font-size: 14px;
          color: #4B4E51;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
