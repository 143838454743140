<template>
  <custom-popup
    class="remark-list"
    :show="show"
    title="备注列表"
    @on-close="$emit('on-close')"
  >
    <div class="pop-content" style="min-height: calc(100vh - 140px)">
      <no-data v-if="!loading && remarkList && remarkList.length === 0" />
      <div
        v-for="(item, index) in remarkList"
        :key="index"
        class="list-item space-between vertical-center"
        @click="handleItemClick(item, index)"
      >
        <div class="info">
          <div class="name">{{ item.description }}</div>
          <div>
            <span v-if="item.id === 'remarks'">
              关联检查点：点数抽样
            </span>
            <span v-else-if="item.inspectItemName">
              关联检查点：{{ item.checkPointName }}
            </span>
            <span v-else>
              关联检查点：无
            </span>
          </div>
        </div>
        <img src="~@/assets/svg/icon-arrow-right.svg" alt=""/>
      </div>
    </div>
    <van-button
      class="new-btn"
      size="large"
      :class="{ disabled: readonly }"
      type="primary"
      color="#055C9D"
      block
      @click="handleAdd"
    >
      添加备注
    </van-button>
    <remark-detail-popup
      ref="detail"
      :show="isShowDetail"
      :article-id="articleId"
      :order-id="orderId"
      @on-close="toggleDetailPopup"
      @on-edit="handleEdit"
      @on-del="handleDel"
    >
    </remark-detail-popup>
    <new-remark-popup
      ref="form"
      :show="isShowForm"
      :article-id="articleId"
      :order-id="orderId"
      :default-check-item-obj="defaultCheckItemObj"
      @on-close="toggleFormPopup"
      @on-ok="handleFormOk"
    >
    </new-remark-popup>
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import RemarkDetailPopup from "@/components/V2/Remark/RemarkDetailPopup.vue";
import NewRemarkPopup from "@/components/V2/Remark/NewRemarkPopup.vue";
import NoData from "@/components/Common/NoData";
import {getAction, deleteAction} from "@/api/manage";
import {mapActions, mapGetters} from "vuex";
import {cloneDeep} from "lodash";

export default {
  name: "RemarkListPopup",
  components: {NoData, NewRemarkPopup, RemarkDetailPopup, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    defaultCheckItemObj: {
      type: Object,
      default() {
        return {}
      },
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      remarkList: [],
      isShowDetail: false,
      isShowForm: false,
      selectIndex: null,
      loading: false,
      order: {},
    };
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  watch: {
    show: function () {
      if (this.show) {
        this.getRemarkList();
      }
    },
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    toggleDetailPopup() {
      this.isShowDetail = !this.isShowDetail;
    },
    toggleFormPopup() {
      this.isShowForm = !this.isShowForm;
    },
    handleItemClick(item, index) {
      this.selectIndex = index;
      this.$refs.detail.disabled = this.readonly;
      this.$refs.detail.form = item;
      this.toggleDetailPopup();
    },
    handleAdd() {
      this.selectIndex = null;
      this.$refs.form.init();
      this.toggleFormPopup();
    },
    handleEdit() {
      this.toggleDetailPopup();
      this.$refs.form.init(this.remarkList[this.selectIndex]);
      this.toggleFormPopup();
    },
    async handleFormOk() {
      this.toggleFormPopup();
      await this.getTaskInfo()
      this.getRemarkList();
    },
    getSubmitData(){
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      article.inspectionList.remarkList = this.remarkList
      return taskInfo
    },
    handleDel() {
      this.Dialog.confirm({
        title: "",
        message: "确认删除该备注？",
      }).then(async () => {
        this.remarkList.splice(this.selectIndex, 1)
        const taskInfo = this.getSubmitData()
        const res = await this.saveTaskInfo(taskInfo)
        if (res.success) {
          await this.getTaskInfo()
          this.toggleDetailPopup()
        }
        this.getRemarkList()
      }).catch((e) => {
        this.getRemarkList()
      });
    },
    async getRemarkList() {
      const orderList = this.taskInfo?.orderList || []
      const order = orderList.find(item => item.id === this.orderId) || {}
      const articleNoList = order?.articleNoList || []
      const article = articleNoList.find(item => item.id === this.articleId)
      this.remarkList = cloneDeep(article?.inspectionList?.remarkList || [])
      if(article.remarks) {
        this.remarkList.unshift({
          checkPointName: null,
          description: article.remarks,
          id: 'remarks'
        })
      }
    },
  },
};
</script>

<style scoped lang="less">
.remark-list {
  .list-item {
    padding: 6px 8px;
    border-bottom: 1px solid #dfe4eb;

    img {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    .info {
      font-size: 14px;

      .name {
        color: rgba(#000, 0.9);
      }

      span {
        color: rgba(#000, 0.4);
        margin-right: 8px;
      }
    }
  }

  .new-btn {
    margin-top: 25px;

    &.disabled {
      display: none;
    }
  }
}
</style>
