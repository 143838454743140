<template>
  <div>
    <Card title="检验清单" class="check-card">
      <van-cell
        v-for="(item, index) in checkPointList"
        :key="index"
        :title="item.pointsName"
        :value="item.conclusion? CHECK_JUDGE_DICT[item.conclusion]: ''"
        :class="item.conclusion ? item.conclusion : 'normal'"
        @click="handleCheckPopup(item, 0)"
      >
        <template #icon>
          <div style="width: 16px;"></div>
        </template>
        <template #right-icon>
          <van-icon name="arrow" class="arrow-icon"/>
        </template>
      </van-cell>
      <div class="custom">
        <van-cell
          v-for="(item, index) in customCheckPointList"
          :key="item.id"
          :title="item.inspectionItemName + '-' + item.checkPointName"
          :value="item.conclusion? CHECK_JUDGE_DICT[item.conclusion]: ''"
          :class="item.conclusion ? item.conclusion : 'normal'"
          @click="handleCheckPopup(item, 1)"
        >
          <template #icon>
            <div style="width: 16px;"></div>
          </template>
          <template #right-icon>
            <van-icon v-if="!readonly" name="delete-o" class="icon-del" @click.stop="handleCustomCheckItemDelClick(index)"/>
            <van-icon name="arrow" class="arrow-icon"/>
          </template>
        </van-cell>
      </div>
    </Card>
    <check-popup
      ref="checkPopup"
      :readonly="readonly"
      :order-id="orderId"
      :article-id="articleId"
      @on-ok="handleCheckPopupOk"
    />
  </div>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import CheckPopup from "@/components/V2/Check/CheckPopup.vue";
import {CHECK_JUDGE_DICT} from "@/constants";
import {cloneDeep} from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CheckItemCard",
  components: {CheckPopup, Card},
  props: {
    articleInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isCheckupBtnDefaultDisable: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      active: [],
      checkPointList: [],
      customCheckPointList: [],
      allCheckList: [],
      CHECK_JUDGE_DICT
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    init(checkPointList, customCheckPointList){
      this.checkPointList = checkPointList
      this.customCheckPointList = customCheckPointList
      this.allCheckList = this.checkPointList.concat(this.customCheckPointList)
    },
    handleCheckPopupOk(){
      this.$emit('handleCheckPopupOk')
    },
    handleCheckPopup(item, isCustom) {
      this.$refs.checkPopup.open(item, isCustom)
      this.$refs.checkPopup.isSaveBtnDisabled = this.isCheckupBtnDefaultDisable
    },
    getSubmitData(customCheckPoint){
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      const index = article.inspectionList.customCheckPointList.findIndex(item => item.id === customCheckPoint.id)
      if(~index) {
        article.inspectionList.customCheckPointList.splice(index, 1)
        article.inspectionList.defectList.forEach(item => {
          if(item.checkPointId === customCheckPoint.id) {
            item.checkPointId = ''
            item.checkPointName = '无'
          }
        })
        article.inspectionList.remarkList = article.inspectionList.remarkList.filter(item => item.checkPointId !== customCheckPoint.id)
      }
      return taskInfo
    },
    handleCustomCheckItemDelClick(index) {
      this.$dialog.confirm({
        title: "",
        message: "确认删除自定义检查点？",
        confirmButtonColor: '#F94837',
        className: "vant-dialog-confirm-red",
      }).then(async () => {
        const customCheckPoint = this.customCheckPointList[index]
        const taskInfo = this.getSubmitData(customCheckPoint)
        const res = await this.saveTaskInfo(taskInfo)
        if (res.success) {
          this.$emit('onCustomCheckItemDel')
          this.Toast.success(res.message || '删除成功')
        } else this.Toast.fail(res.message);
      }).catch(() => {
      });
    },
  }
}
</script>

<style scoped lang="less">
.check-card {
  ::v-deep {
    .card-content {
      padding-top: 1px;

      .van-collapse-item {
        .van-collapse-item__title {
          padding: 10px 0;

          .van-cell__right-icon {
            display: none;
          }
        }

        &::after, .van-collapse-item__title--expanded::after {
          left: 0;
          right: 0;
          border-color: #DFE4EB;
        }

        .van-collapse-item__content {
          padding: 0;
        }
      }

      .van-cell {
        padding: 12px 8px;

        &::after {
          left: 0;
          right: 0;
        }

        .van-cell__title, .van-cell__value {
          flex: unset;
        }

        .van-cell__title {
          width: 80%;
        }

        .van-cell__value {
          width: 20%;
        }

        .arrow-icon, .icon-del {
          margin-top: 5px;
          margin-left: 5px;
        }

        .icon-del {
          display: block;
          text-align: right;
        }

        &.QUALIFIED {
          .van-cell__value {
            color: #00bf46;
          }
        }

        &.FAILED {
          .van-cell__value {
            color: #f94837;
          }
        }

        &.PENDING {
          .van-cell__value {
            color: #ffad16;
          }
        }

        &.UNCHECKED {
          .van-cell__value {
            color: #055c9d;
          }
        }
      }
    }
  }

  .collapse-box {
    margin-top: -1px;

    &::after {
      border-top: none;
      border-color: #DFE4EB;
    }

    .check-item {
      position: relative;

      .caret-img {
        width: 16px;
        height: 16px;
        margin-left: 8px;

        &.is-active {
          transform: rotate(90deg);
        }
      }

      &.check-item-2 .caret-img {
        margin-left: 32px;
      }

      &.check-item-3 .caret-img {
        margin-left: 60px;
      }

      .right-box {
        position: absolute;
        right: 40px;

        .del-img {
          margin-left: 16px;
          width: 16px;
          height: 16px;
        }
      }
    }

    .check-detail {
      white-space: pre-line;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      text-align: left;
      line-height: 22px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 8px;

      &.check-detail-1 {
        padding-left: 64px;
      }

      &.check-detail-2 {
        padding-left: 80px;
      }

      &.check-detail-3 {
        padding-left: 96px;
      }
    }
  }

  .custom{
    position: relative;
    &::after {
      border-bottom: 1px solid #DFE4EB;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      position: absolute;
      box-sizing: border-box;
      content: ' ' !important;
      pointer-events: none;
      transform: scale(.5)
    }
  }
}
</style>