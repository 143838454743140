<template>
  <div class="no-data">暂无数据</div>
</template>

<script>
export default {
  name: "NoData",
  data(){
    return {}
  }
}
</script>

<style lang="less" scoped>
.no-data{
  text-align: center;
  font-size: 14px;
  margin: 80px 0;
  font-family: SourceHanSansSC-Regular;
  color: rgba(0,0,0,0.60);
  letter-spacing: 0;
  line-height: 22px;
}
</style>
