<template>
  <div>
    <check-defect-remark-detail
      :article-check-point-id="articleCheckPointId"
      :remark-list="remarkList"
      :defect-list="defectList"
    ></check-defect-remark-detail>
  </div>
</template>

<script>
import {cloneDeep} from "lodash";
import {imgStrToList} from "@/utils/util";
import {mapGetters} from "vuex";
import CheckDefectRemarkDetail from "@/components/Common/CheckDefectRemarkDetail.vue";

export default {
  name: "CheckItemDefectRemark",
  components: {CheckDefectRemarkDetail},
  props: {
    articleCheckPointId: {
      type: String,
      default: ''
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      defectList: [],
      remarkList: [],
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  watch: {
    taskInfo: {
      immediate: true,
      handler: function () {
        this.getArticleInfo()
      }
    },
  },
  methods: {
    getArticleInfo() {
      const orderList = this.taskInfo.orderList || []
      const order = orderList.find(item => item.id === this.orderId) || {}
      const articleNoList = order.articleNoList || []
      const articleInfo = articleNoList.find(item => item.id === this.articleId) || {}
      const defectList = cloneDeep(articleInfo?.inspectionList?.defectList || [])
      this.defectList = defectList.map(item => {
        item.picturesList = imgStrToList(item.pictures)
        return item
      })
      const remarkList = cloneDeep(articleInfo?.inspectionList?.remarkList || [])
      this.remarkList = remarkList.map(item => {
        item.picturesList = imgStrToList(item.pictures)
        return item
      })
    },
  },
}
</script>

<style lang="less" scoped>
.form-item {
  display: flex;
  align-items: flex-start;

  &.remark-form-item {
    margin-top: 20px;
  }

  &.defect-form-item {
    margin-top: 8px;
  }

  .label {
    width: 80px;
    margin: 0 4px;
    font-size: 12px;
    color: #AAB3BA;
    flex-shrink: 0;
  }

  ::v-deep.el-radio-group {
    display: flex;

    .el-radio {
      margin-right: 5px;

      .el-radio__label {
        padding-left: 2px;
      }
    }
  }

  .info {
    .name {
      font-size: 14px;
      color: #4B4E51;
      margin-bottom: 16px;

      span {
        margin-right: 8px;
      }
    }

    .desc {
      font-size: 12px;
      color: #797C80;
      margin-bottom: 10px;
    }

    .picture-wrap {

      img {
        width: 80px;
        height: 60px;
        object-fit: cover;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>