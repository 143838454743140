<template>
  <Card title="产品验货照片">
    <div
      v-if="!showUpload && !deletable && inspectionPictures && inspectionPictures.length === 0"
      class="text-info-item"
    >
      <div class="label">产品验货照片</div>
      <div class="value">无</div>
    </div>
    <template v-else>
      <div class="desc-text">
        拍摄产品完整组装后各个角度的照片
      </div>
      <div :class="['info-item space-between', showUpload ? '' : 'readonly']">
        <image-uploader
          v-model="inspectionPictures"
          :show-upload="showUpload"
          :deletable="deletable"
          @input="onChange"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import ImageUploader from "@/components/Common/ImageUploader.vue";

export default {
  name: "InspectionPicturesCard",
  components: {ImageUploader, Card},
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    deletable: {
      type: Boolean,
      default: true
    },
    showUpload: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.inspectionPictures = this.value
      }
    }
  },
  data() {
    return {
      inspectionPictures: [],
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.inspectionPictures)
    }
  }
}
</script>

<style lang="less" scoped>
.no-data-text {
  color: rgb(121, 124, 128);
  font-size: 12px;
}

.desc-text {
  margin-bottom: 8px;
}

.info-item {
  font-size: 12px;
  color: #AAB3BA;

  &.readonly {
    flex-direction: column;

    .desc-text {
      font-size: 12px;
      color: #4B4E51;
      margin: 16px 0;
    }
  }
}

.text-info-item {
  line-height: 20px;
  margin-bottom: 8px;
  display: flex;

  &:last-of-type {
    margin-bottom: 0;
  }

  .label {
    font-size: 12px;
    color: #AAB3BA;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .value {
    font-size: 14px;
    word-break: break-all;
    color: #4B4E51;
  }
}
</style>