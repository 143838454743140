<template>
  <div class="check-form">
    <div class="info-item">
      <div class="label">检查要求</div>
      <div class="value">
        <div>{{ itemObj.description }}</div>
      </div>
    </div>
    <div class="info-item">
      <div class="label">拍照要求</div>
      <div class="value-box">
        <div
          v-if="itemObj.pictureNum"
          class="value"
        >
          1.拍照数量:{{ itemObj.pictureNum }}张
        </div>
        <div v-if="itemObj.pictureDescription" class="value">
          {{ photoDescIndex }}.拍照要求：{{ itemObj.pictureDescription }}
        </div>
        <div v-if="examplePicture && examplePicture.length" class="value">
          {{ exampleIndex }}.拍照示范图
        </div>
        <div v-if="!examplePicture.length && exampleIndex === 1" class="value">
          无
        </div>
        <van-uploader
          v-model="examplePicture"
          readonly
          :show-upload="false"
          :deletable="false"
          :preview-size="69"
          :upload-icon="require('@/assets/svg/icon-camera.svg')"
        />
      </div>
    </div>
    <div class="info-item">
      <div :class="['label',itemObj.needPictures === 'Y' ? 'required' : '',]">
        拍照上传
      </div>
      <imageUploader v-model="form.pictures" :show-upload="!readonly" :deletable="!readonly"/>
    </div>
    <div v-if="(assistFiles && assistFiles.length) || (assistPictures && assistPictures.length)" class="info-item">
      <div class="label">检查项辅助文件</div>
      <div class="value">
        <div
          v-for="(file, index) in assistFiles"
          :key="index"
          class="space-between file"
        >
          <div class="name">{{ file.name }}</div>
          <a target="_blank" :href="file.url" class="btn" @click="handleDownload(file.url)">下载</a>
        </div>
        <van-uploader
          v-model="assistPictures"
          readonly
          :show-upload="false"
          :deletable="false"
          :preview-size="69"
          :upload-icon="require('@/assets/svg/icon-camera.svg')"
        />
      </div>
    </div>
    <div class="info-item">
      <div class="label">检查点判定</div>
      <div class="radio-group">
        <van-radio-group v-model="form.conclusion" direction="horizontal" :disabled="readonly" @change="onRadioChange">
          <van-radio
            v-for="key in Object.keys(CHECK_JUDGE_DICT)"
            :key="key"
            :name="key"
            checked-color="#055C9D"
            icon-size="12"
            @click.native.prevent="handleRadioClick"
          >
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked? require('@/assets/svg/icon-radio-checked.svg'): require('@/assets/svg/icon-radio.svg')"
              />
            </template>
            {{ CHECK_JUDGE_DICT[key] }}
          </van-radio>
        </van-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import DefectListPopup from "@/components/V2/Defect/DefectListPopup.vue";
import RemarkListPopup from "@/components/V2/Remark/RemarkListPopup.vue";
import imageUploader from "@/components/Common/ImageUploader.vue";
import {
  CHECK_JUDGE_DICT,
} from "@/constants";
import {fileStrToList, imgStrToList} from "@/utils/util";
import {isNative} from "@/config";

export default {
  name: "CustomCheckForm",
  props: {
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {RemarkListPopup, DefectListPopup, imageUploader},
  data() {
    return {
      CHECK_JUDGE_DICT,
      assistFiles: [],
      assistPictures: [],
      examplePicture: [],
      form: {
        pictures: [],
        conclusion: "",
      },
      itemObj: {},
      checkRadioFlag: false
    };
  },
  computed: {
    photoDescIndex() {
      if (this.itemObj.pictureNum) {
        return 2;
      } else return 1;
    },
    exampleIndex() {
      if (this.itemObj.pictureDescription) {
        return this.photoDescIndex + 1;
      } else {
        return this.photoDescIndex;
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.$emit("change", {...this.itemObj, ...this.form});
      }
    },
  },
  methods: {
    handleRadioClick(val){
      if(!this.checkRadioFlag) {
        this.form.conclusion = '';
      }
      this.checkRadioFlag = false
    },
    onRadioChange(){
      this.checkRadioFlag = true
    },
    setForm(itemObj) {
      this.itemObj = itemObj
      this.form = {
        pictures: itemObj.pictures,
        conclusion: itemObj.conclusion,
        id: itemObj.id
      }
      this.assistFiles = fileStrToList(itemObj.assistFiles)
      this.assistPictures = imgStrToList(itemObj.assistPictures)
      this.examplePicture = imgStrToList(itemObj.examplePicture)
    },
    validate() {
      let checkName = `${this.itemObj.inspectionItemName}-${this.itemObj.checkPointName}`;
      // 校验拍照记录
      if (
        this.itemObj.needPictures === "Y" &&
        this.itemObj.pictureNum &&
        this.form.pictures.length < this.itemObj.pictureNum
      ) {
        this.Toast.fail(`${checkName}拍照记录未达到要求`);
        return false;
      }
      // // 校验检验结果
      // if (!this.form.conclusion) {
      //   this.Toast.fail(`${checkName}未选择检查点判定结果`);
      //   return false;
      // }
      // 校验拍照记录是否上传完成
      if (this.form.pictures && this.form.pictures.find((item) => !item.url)) {
        console.log(
          "等待form:",
          this.form.pictures.find((item) => !item.url),
          this.form
        );
        this.Toast.fail(`请等待${checkName}的图片上传完成`);
        return false;
      }
      return true;
    },
    async handleDownload(url) {
      if (isNative) {
        try {
          const res = await window.nativeApp.fileDownload(url);
          console.log('下载操作结果为：', res)
        } catch (e) {
          console.log('下载操作错误：')
          console.error(e)
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.check-form {
  padding: 16px;
  margin-right: -20px;
  padding-right: 20px;
  max-height: calc(85vh - 214px);

  .info-item {
    margin-bottom: 8px;
    display: flex;

    .label {
      flex-shrink: 0;
      width: 80px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: 0;
      padding-top: 2px;

      &.required {
        position: relative;
      }

      &.required::after {
        content: "*";
        color: #f75658;
        margin-left: 2px;
        position: absolute;
      }
    }

    .value {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      letter-spacing: 0;
      line-height: 22px;
      white-space: pre-line;
      max-width: calc(100% - 80px);

      .file {
        .name {
          width: calc(100% - 60px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .btn {
          width: 40px;
          color: #055c9d;
          font-size: 12px;
        }
      }
    }

    .value-box {
      width: calc(100% - 80px);

      .value {
        max-width: 100%;
      }
    }

    .tip {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: 0;
      line-height: 20px;
      margin: 8px 0;
    }

    .demo-img-box {
      display: flex;

      img {
        height: 70px;
        margin-right: 8px;
      }
    }

    .radio-group {
      width: calc(100% - 80px);

      .img-icon {
        width: 12px;
        height: 12px;
      }

      ::v-deep {
        .van-radio {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }

        .van-radio__label {
          margin-left: 4px;
          height: 22px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
        }
      }
    }
  }

  .scan-box {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);

    .scan-btn {
      margin-left: 80px;
      width: 70px;
      height: 70px;
      background: #f9f9f9;
      border-radius: 2px;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .btn-box {
    margin-top: 16px;

    .van-button {
      width: calc((100% - 20px) / 2);
    }
  }
}
</style>
