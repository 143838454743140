<template>
  <custom-popup
    class="new-check-point"
    :show="show"
    title="新增自定义检查点"
    @on-close="$emit('on-close')"
  >
    <div class="content">
      <field-item label="检查项名称" required>
        <el-input v-model="form.inspectionItemName" placeholder="请输入" size="small" @blur="handleInputBlur"/>
      </field-item>
      <field-item label="检查点名称" required>
        <el-input v-model="form.checkPointName" placeholder="请输入" size="small" @blur="handleInputBlur"/>
      </field-item>
      <field-item label="检查点文字描述" required>
        <el-input v-model="form.description" placeholder="请输入检查点文字描述" type="textarea" :rows="3"
                  @blur="handleInputBlur"/>
      </field-item>
      <field-item label="检查项辅助文件">
        <div class="uploader-file-img-box">
          <div class="upload-tip">支持 JPG、GIF、PNG、PDF、DOC、EXCEL 格式文件</div>
          <div class="file-box">
            <div v-if="form.assistFiles.length">
              <div v-for="(item, index) in form.assistFiles" :key="index" class="item space-between">
                <div class="file-name">
                  <img v-if="item.status === 'uploading'" src="~@/assets/svg/icon-upload-loading.svg" alt="" class="loading-img">
                  <img v-else-if="item.status === 'success'" src="~@/assets/svg/icon-upload-check.svg" alt="">
                  <div class="name">{{ item.file.name }}</div>
                </div>
                <div class="del-btn center" @click="handleFileDel(index)">删除</div>
              </div>
            </div>
            <imageUploader v-model="form.assistFiles" accept=".xls,.xlsx,.ppt,.pptx,.doc,.docx,.pdf" :is-img="false" :preview-image="false">
              <van-button color="#055C9D" plain class="upload-btn">上传文件</van-button>
            </imageUploader>
          </div>
        </div>
      </field-item>
      <field-item label="">
        <imageUploader
          v-model="form.assistPictures"
          :preview-size="70"
          :upload-icon="require('@/assets/svg/icon-upload-add.svg')"
          class="image-uploader">
          <van-button color="#055C9D" plain class="upload-btn">上传图片</van-button>
        </imageUploader>
      </field-item>
      <field-item label="是否有拍照需求">
        <van-switch v-model="form.needPictures" active-color="#055C9D" active-value="Y" inactive-value="N"/>
      </field-item>
      <field-item label="拍照数量" :required='form.needPictures === "Y"'>
        <el-select v-model="form.pictureNum" placeholder="请选择" size="small" style="width: 100%;">
          <el-option :label="1" :value="1"></el-option>
          <el-option :label="2" :value="2"></el-option>
          <el-option :label="3" :value="3"></el-option>
          <el-option :label="4" :value="4"></el-option>
        </el-select>
      </field-item>
      <field-item label="拍照示范图">
        <imageUploader
          v-model="form.examplePicture"
          :preview-size="70"
          :upload-icon="require('@/assets/svg/icon-upload-add.svg')"
          class="image-uploader"
        >
          <van-button color="#055C9D" plain class="upload-btn">上传图片</van-button>
        </imageUploader>
      </field-item>
      <field-item label="拍照要求描述">
        <el-input
          v-model="form.pictureDescription"
          placeholder="请输入"
          type="textarea"
          :rows="3"
          @blur="handleInputBlur"
        />
      </field-item>
    </div>
    <div class="btn-wrapper space-between">
      <van-button class="btn" color="#055C9D" size="large" plain @click="handleCancel">取消</van-button>
      <van-button
        class="btn"
        color="#055C9D"
        size="large"
        :loading="loading"
        :disabled="loading"
        type="primary"
        @click="handleAdd"
      >
        新增
      </van-button>
    </div>
  </custom-popup>
</template>

<script>
import CustomPopup from '@/components/Common/CustomPopup.vue'
import FieldItem from "@/components/Common/FieldItem.vue";
import _, {cloneDeep} from 'lodash'
import {axios} from '@/utils/request';
import {postAction} from '@/api/manage';
import {debounce} from 'lodash'
import {mapActions, mapGetters} from "vuex";
import imageUploader from "@/components/Common/ImageUploader.vue";
import {getRandomId} from "@/utils/util";

export default {
  name: 'NewCheckPointPopup',
  components: {FieldItem, CustomPopup, imageUploader},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        id: getRandomId(),
        inspectionItemName: '',
        checkPointName: '',
        description: '',
        assistPictures: [],
        assistFiles: [],
        needPictures: false,
        pictureNum: null,
        pictureDescription: '',
        examplePicture: []
      },
      loading: false,
    }
  },
  mounted() {
    this.Toast.clear()
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    handleInputBlur() {
      window.scroll(0, 0);
    },
    initForm() {
      this.form = {
        id: getRandomId(),
        inspectionItemName: '',
        checkPointName: '',
        description: '',
        assistPictures: [],
        assistFiles: [],
        needPictures: false,
        pictureNum: null,
        pictureDescription: '',
        examplePicture: []
      }
    },
    handleFileDel(index) {
      this.form.assistFiles.splice(index, 1)
    },
    handleCancel() {
      this.initForm()
      this.$emit('on-close')
    },
    isFormValid() {
      if (!this.form.inspectionItemName) {
        this.$toast('请输入检查项名称');
        return false
      } else if (!this.form.checkPointName) {
        this.Toast('请输入检查点名称');
        return false
      } else if (!this.form.description) {
        this.Toast('请输入检查点文字描述');
        return false
      } else if (this.form.needPictures === 'Y') {
        if (!this.form.pictureNum) {
          this.Toast('请选择拍照数量');
          return false
        } else if (!/^[0-9]*[1-9][0-9]*$/.test(this.form.pictureNum)) {
          this.Toast('拍照数量必须正整数');
          return false
        }
      }
      if (this.form.assistFiles.find(item => !item.url)) {
        this.Toast('请等待文件上传完成！');
        return false
      }
      if (this.form.assistPictures.find(item => !item.url)) {
        this.Toast('请等待图片上传完成！');
        return false
      }
      if (this.form.examplePicture.find(item => !item.url)) {
        this.Toast('请等待拍照示范图上传完成！');
        return false
      }
      return true
    },
    getSubmitData(){
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      article.inspectionList.customCheckPointList.push({
        ...this.form,
        assistFiles: this.form.assistFiles.map(item => item.url).join(','),
        assistPictures: this.form.assistPictures.map(item => item.url).join(','),
        examplePicture: this.form.examplePicture.map(item => item.url).join(','),
      })
      return taskInfo
    },
    handleAdd: debounce(async function () {
      const valid = this.isFormValid()
      if (valid) {
        const data = this.getSubmitData()
        this.loading = true
        const res = await this.saveTaskInfo(data)
        this.loading = false
        if (res.success) {
          this.initForm()
          this.$emit('on-ok')
        }
      }
    }, 500),
  }
}
</script>

<style scoped lang="less">
.new-check-point::v-deep {
  .content {
    margin-right: -20px;
    padding-right: 20px;
    max-height: calc(85vh - 132px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .btn-wrapper {
    margin-top: 22px;

    .btn {
      width: 163px;
    }
  }

  .upload-tip {
    font-size: 12px;
    color: #4B4E51;
    padding-right: 32px;
    margin-bottom: 10px;
  }

  .uploader-file-img-box {
    width: 100%;
  }

  .upload-btn {
    height: 36px;
  }

  .image-uploader {
    .van-uploader__input-wrapper {
      width: 100%;
    }
  }

  .file-box {
    width: 100%;

    .item {
      width: 100%;
      margin-bottom: 8px;

      .file-name {
        width: calc(100% - 72px - 17px);
        padding-right: 17px;
        height: 32px;
        background: rgba(255, 255, 255, 0.90);
        border: 1px solid #DCDCDC;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          margin: 0 8px;
          &.loading-img {
            -moz-animation:loadingRotate 1s linear infinite;
            -webkit-animation:loadingRotate 1s linear infinite;
            -o-animation:loadingRotate 1s linear infinite;
            animation:loadingRotate 1s linear infinite;
          }
        }

        .name {
          display: inline-block;
          width: calc(100% - 16px - 16px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .del-btn {
        width: 60px;
        height: 32px;
        background: rgba(255, 255, 255, 0.90);
        border: 1px solid #DCDCDC;
        border-radius: 3px;
        margin-left: 12px;
        flex-shrink: 0;
      }
    }
  }
}
</style>
