<template>
  <custom-popup
    class="new-check-point"
    :show="show"
    :title="title"
    @on-close="show = false"
  >
    <div :class="['pop-content', readonly ? 'pop-content-readonly' : '']">
      <check-form
        v-if="!isCustom"
        ref="checkForm"
        :visible.sync="show"
        :readonly="readonly"
        :order-id="orderId"
        :article-id="articleId"
        @onChange="activeSaveButton"
      />
      <custom-check-form
        ref="checkForm"
        v-else
        :readonly="readonly"
        :order-id="orderId"
        :article-id="articleId"
        @change="onCustomFormChange"
      />
      <check-item-defect-remark
        :order-id="orderId"
        :article-id="articleId"
        :article-check-point-id="checkItem.id"
      ></check-item-defect-remark>
    </div>
    <div class="conclusion-wrap">
      <div class="label">检查结果：</div>
      <el-radio-group
        v-model="checkItem.conclusion"
        :disabled="readonly"
      >
        <el-radio
          v-for="key in Object.keys(CHECK_JUDGE_DICT)"
          :key="key"
          :label="key"
          @click.native.prevent="handleRadioClick(key)"
        >
          {{ CHECK_JUDGE_DICT[key] }}
        </el-radio>
      </el-radio-group>
    </div>
    <div v-show="!readonly" class="btn-wrapper space-between" style="margin-top: 0">
      <van-button
        class="btn"
        color="#055C9D"
        @click="toggleDefectPopup"
        plain
      >
        添加缺陷记录
      </van-button>
      <van-button
        class="btn"
        color="#055C9D"
        @click="toggleRemarkPopup"
        plain
      >
        添加备注
      </van-button>
    </div>
    <div v-show="!readonly" class="btn-wrapper space-between">
      <van-button class="block-btn" color="#055C9D" block :disabled="isSaveBtnDisabled" @click="handSure">
        确定
      </van-button>
    </div>
    <defect-list-popup
      ref="defectListPopup"
      :show="isShowDefectListPop"
      :default-check-item-obj="{...checkItem, custom: isCustom}"
      :order-id="orderId"
      :readonly="readonly"
      :article-id="articleId"
      @on-close="toggleDefectPopup"
    />
    <remark-list-popup
      ref="remarkListPopup"
      :show="isShowRemarkListPop"
      :default-check-item-obj="{...checkItem, custom: isCustom}"
      :order-id="orderId"
      :readonly="readonly"
      :article-id="articleId"
      @on-close="toggleRemarkPopup"
    />
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import CheckForm from '@/components/V2/Check/CheckForm';
import CustomCheckForm from "@/components/V2/Check/CustomCheckForm.vue";
import NewCheckPointPopup from "@/components/V2/Check/NewCheckPointPopup.vue";
import {mapActions, mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import RemarkListPopup from "@/components/V2/Remark/RemarkListPopup.vue";
import DefectListPopup from "@/components/V2/Defect/DefectListPopup.vue";
import {imgStrToList} from "@/utils/util";
import {CHECK_JUDGE_DICT} from "@/constants";
import CheckItemDefectRemark from "@/components/V2/Check/CheckItemDefectRemark.vue";

export default {
  name: "CheckPopup",
  components: {
    CheckItemDefectRemark,
    DefectListPopup, RemarkListPopup, NewCheckPointPopup, CustomPopup, CheckForm, CustomCheckForm},
  props: {
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowDefectListPop: false,
      isShowRemarkListPop: false,
      title: "",
      show: false,
      loading: false,
      isCustom: 0,
      checkItem: {},
      isSaveBtnDisabled: true,
      CHECK_JUDGE_DICT,
    };
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    open(item, isCustom) {
      this.show = true
      this.isCustom = isCustom
      this.checkItem = cloneDeep(item)
      if (isCustom) {
        this.title = item.inspectionItemName + '-' + item.checkPointName
        this.checkItem.pictures = imgStrToList(this.checkItem.pictures)
      } else {
        // this.title = item.label + '-' + item.pointsName
        this.title = item.pointsName
      }
      setTimeout(() => {
        this.$refs.checkForm.stopListenDataValue()
        this.$refs.checkForm.setForm(this.checkItem);
        this.$refs.checkForm.startListenDataValue()
      });
    },
    onCustomFormChange(form) {
      this.checkItem = {
        ...this.checkItem,
        ...form
      }
    },
    getSubmitData() {
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      if (this.isCustom) {
        const index = article.inspectionList.customCheckPointList.findIndex(item => item.id === this.checkItem.id)
        if (~index) {
          const checkItem = cloneDeep(this.checkItem)
          delete checkItem.children
          article.inspectionList.customCheckPointList[index] = {
            ...checkItem,
            pictures: this.checkItem.pictures.map(item => item.url).join(',')
          }
        }
      } else {
        const index = article.inspectionList.checkPointList.findIndex(item => item.id === this.checkItem.id)
        if (~index) {
          const checkItem = cloneDeep(this.checkItem)
          delete checkItem.children
          article.inspectionList.checkPointList[index] = {
            ...checkItem,
            updateFlag: 'Y'
          }
        }
      }
      return taskInfo
    },
    toggleDefectPopup() {
      this.isShowDefectListPop = !this.isShowDefectListPop;
      if (this.isShowDefectListPop) {
        this.$nextTick(() => {
          this.$refs.defectListPopup.handleAdd()
        })
      }
    },
    toggleRemarkPopup() {
      this.isShowRemarkListPop = !this.isShowRemarkListPop;
      if (this.isShowRemarkListPop) {
        this.$nextTick(() => {
          this.$refs.remarkListPopup.handleAdd()
        })
      }
    },
    handleRadioClick(e){
      if (this.readonly) return
      if(this.checkItem.conclusion === e) {
        this.$set(this.checkItem, 'conclusion', '')
      } else {
        this.$set(this.checkItem, 'conclusion', e)
      }
      this.handleSelectConclusion()
    },
    async handSure() {
      if (!this.isCustom) {
        this.$refs.checkForm.validate(async (valid, func) => {
          if (valid) {
            await this.submitData()
            func()
          }
        })
      } else if (this.$refs.checkForm.validate()) {
        this.submitData()
      }
    },
    getCheckResult(dataValue) {
      const formOption = JSON.parse(this.checkItem.form)
      const column = formOption.column || []
      let checkResultItem = column.find(item => item.dataProp === 'checkResult')
      if (dataValue && checkResultItem) {
        const prop = checkResultItem.prop
        return dataValue[prop] || ''
      }
      return ''
    },
    validateDefect(conclusion) {
      if (conclusion === 'FAILED') {
        const taskInfo = cloneDeep(this.taskInfo)
        const order = taskInfo.orderList.find(item => item.id === this.orderId)
        const article = order.articleNoList.find(item => item.id === this.articleId)
        const defectList = article.inspectionList.defectList
        let index = -1
        if (this.isCustom) {
          index = defectList.findIndex(item => item.checkPointId === this.checkItem.id)
        } else {
          index = defectList.findIndex(item => item.checkPointId === this.checkItem.checkPointsId)
        }
        if (!~index) {
          this.$toast.fail("请添加缺陷佐证该检查点不合格")
          return false
        }
      }
      return true
    },
    async submitData() {
      if (!this.isCustom) {
        const dataValue = this.$refs.checkForm.dataValue
        this.checkItem.dataValue = JSON.stringify(dataValue)
        // this.checkItem.conclusion = this.getCheckResult(dataValue)
      }
      const valid = this.validateDefect(this.checkItem.conclusion)
      if (!valid) return
      this.show = false;
      const taskInfo = this.getSubmitData()
      this.loading = true
      const res = await this.saveTaskInfo(taskInfo)
      this.loading = false
      if (res.success) {
        this.$emit('on-ok')
      }
    },
    activeSaveButton() {
      this.isSaveBtnDisabled = false
    },
    handleSelectConclusion() {
      this.activeSaveButton()
      // console.log(this.checkItem);
    }
  },
};
</script>

<style scoped lang="less">
.new-check-point::v-deep {
  overflow: hidden;

  .pop-content {
    padding-top: 5px;
    min-height: calc(100vh - 228px);
    &.pop-content-readonly {
      min-height: calc(100vh - 97px);
    }
  }

  .conclusion-wrap {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;

    .label {
      width: 120px;
      margin: 0 4px;
      font-size: 12px;
      color: #AAB3BA;
      //flex-shrink: 0;
    }

    .el-radio__inner{
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      border: 1px solid #dcdfe6 !important;
    }

    .el-radio-group {
      display: flex;

      .el-radio {
        margin-right: 5px;

        .el-radio__label {
          padding-left: 2px;
        }
      }
    }
  }

  .btn-wrapper {
    padding-top: 22px;

    .btn {
      width: 163px;
    }
  }
}
</style>
