<template>
  <custom-popup
    class="defect-list"
    :show="show"
    title="缺陷列表"
    @on-close="$emit('on-close')"
  >
    <div class="pop-content" style="min-height: calc(100vh - 140px)">
      <no-data v-if="!loading && defectList && defectList.length === 0"></no-data>
      <div v-for="(item, index) in defectList"
           :key="index"
           class="list-item space-between vertical-center"
           @click="handleItemClick(item, index)"
      >
        <div class="info">
          <div class="name">
            【
            <span>CRI {{ item.cri }}</span>
            <span>MAJ {{ item.maj }}</span>
            <span style="margin-right: 0">MIN {{ item.min }}</span>
            】
            <span style="margin-left: 8px">{{ item.description }}</span>
          </div>
          <div>
            <span>缺陷类别：{{ item.defectTypeName }}/{{ item.defectName }}</span>
            <br>
            <span>关联检查点：{{ item.checkPointName }}</span>
          </div>
        </div>
        <img src="~@/assets/svg/icon-arrow-right.svg" alt="">
      </div>
    </div>
    <van-button
      class="new-btn"
      size="large"
      :class="{'disabled': readonly}"
      type="primary"
      color="#055C9D"
      block
      @click="handleAdd"
    >
      添加缺陷
    </van-button>
    <defect-detail-popup
      ref="detail"
      :show="isShowDetail"
      @on-close="toggleDetailPopup"
      @on-edit="handleEdit"
      @on-del="handleDel"
    >
    </defect-detail-popup>
    <new-defect-popup
      ref="form"
      :show="isShowForm"
      :order-id="orderId"
      :article-id="articleId"
      :default-check-item-obj="defaultCheckItemObj"
      @on-close="toggleFormPopup"
      @on-ok="handleFormOk"
    >
    </new-defect-popup>
  </custom-popup>
</template>

<script>
import CustomPopup from '@/components/Common/CustomPopup.vue'
import DefectDetailPopup from '@/components/V2/Defect/DefectDetailPopup.vue';
import NewDefectPopup from '@/components/V2/Defect/NewDefectPopup.vue';
import NoData from '@/components/Common/NoData';
import {deleteAction, getAction} from '@/api/manage';
import {mapActions, mapGetters} from 'vuex';
import {cloneDeep} from "lodash";
import {getDocumentTitle} from "@/utils/taskData";

export default {
  name: 'DefectListPopup',
  components: {NoData, NewDefectPopup, DefectDetailPopup, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    defaultCheckItemObj: {
      type: Object,
      default() {
        return {}
      },
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      defectList: [],
      isShowDetail: false,
      isShowForm: false,
      selectIndex: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  watch: {
    show: function () {
      if (this.show) {
        this.getDefectList()
      }
    }
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    toggleDetailPopup() {
      this.isShowDetail = !this.isShowDetail
    },
    toggleFormPopup() {
      this.isShowForm = !this.isShowForm
    },
    handleItemClick(item, index) {
      this.selectIndex = index
      this.$refs.detail.disabled = this.readonly
      this.$refs.detail.form = item
      this.toggleDetailPopup()
    },
    handleAdd() {
      this.selectIndex = null
      this.$refs.form.init()
      this.toggleFormPopup()
    },
    handleEdit() {
      this.toggleDetailPopup()
      this.$refs.form.init(this.defectList[this.selectIndex])
      this.toggleFormPopup()
    },
    async handleFormOk() {
      this.toggleFormPopup()
      await this.getTaskInfo()
      this.getDefectList()
    },
    getSubmitData(){
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      article.inspectionList.defectList = this.defectList
      return taskInfo
    },
    handleDel() {
      this.Dialog.confirm({
        title: '',
        message: '确认删除该缺陷？',
      }).then(async () => {
        this.defectList.splice(this.selectIndex, 1)
        const taskInfo = this.getSubmitData()
        const res = await this.saveTaskInfo(taskInfo)
        if (res.success) {
          await this.getTaskInfo()
          this.toggleDetailPopup()
        }
        this.getDefectList()
      }).catch(async () => {
        this.getDefectList()
      });
    },
    async getDefectList() {
      const orderList = this.taskInfo?.orderList || []
      const order = orderList.find(item => item.id === this.orderId)
      const articleNoList = order?.articleNoList || []
      const article = articleNoList.find(item => item.id === this.articleId)
      this.defectList = cloneDeep(article?.inspectionList?.defectList || [])
    }
  },
}
</script>

<style scoped lang="less">
.defect-list {

  .list-item {
    padding: 6px 8px;
    border-bottom: 1px solid #DFE4EB;

    img {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    .info {
      font-size: 14px;

      span {
        color: rgba(#000, 0.4);
        margin-right: 8px;
      }

      .name {
        color: rgba(#000, 0.9);
        span {
          color: rgba(#000, 0.9);
        }
      }
    }
  }

  .new-btn {
    margin-top: 25px;

    &.disabled {
      display: none;
    }
  }
}
</style>
