<template>
  <custom-popup
    class="new-defect"
    :show="show"
    :title="isEdit ? '编辑缺陷' : '录入新缺陷'"
    @on-close="$emit('on-close')"
  >
    <div class="pop-content">
      <field-item label="检查点">
        <el-select
          v-model="form.checkName"
          readonly
          class="check-category"
          size="small"
          placeholder="请选择检查点"
          @focus="isShowCheckPopup = true"
        ></el-select>
      </field-item>
      <field-item label="缺陷类别" required>
        <el-select
          v-model="form.category"
          readonly
          class="check-category"
          size="small"
          placeholder="请选择"
          @focus="isShowCategory = true">
        </el-select>
      </field-item>
      <!--      <field-item label="缺陷名称" required>-->
      <!--        <el-input v-model="form.name" placeholder="请输入" size="small" @blur="handleInputBlur"/>-->
      <!--      </field-item>-->
      <field-item label="缺陷描述" required>
        <el-input
          v-model="form.description"
          placeholder="请输入"
          type="textarea"
          :rows="3"
          :maxlength="100"
          @blur="handleInputBlur"
        />
      </field-item>
      <field-item label="缺陷图片">
        <imageUploader v-model="form.pictures" :max-count="10"></imageUploader>
      </field-item>
      <field-item label="缺陷统计" required>
        <div class="statistic-wrapper">
          <div class="statistic-item vertical-center">
            <div class="name">致命（ CRI ）</div>
            <counter v-model="form.cri"/>
          </div>
          <div class="statistic-item vertical-center">
            <div class="name">严重（ MAJ）</div>
            <counter v-model="form.maj"/>
          </div>
          <div class="statistic-item vertical-center">
            <div class="name">轻微（ MIN ）</div>
            <counter v-model="form.min"/>
          </div>
        </div>
      </field-item>
    </div>
    <div class="btn-wrapper space-between">
      <van-button
        :loading="loading"
        :disabled="loading"
        size="large"
        class="btn"
        color="#055C9D"
        type="primary"
        @click="handleOk"
      >
        {{ isEdit ? '确定修改' : '确定添加' }}
      </van-button>
    </div>
    <van-popup v-model="isShowCategory" round position="bottom" get-container="body">
      <van-cascader
        v-model="cascaderValue"
        active-color="#055C9D"
        title="缺陷类别"
        :field-names="fieldNames"
        :options="options"
        @close="isShowCategory = false"
        @finish="onFinish"
      />
    </van-popup>
    <van-popup
      v-model="isShowCheckPopup"
      round
      position="bottom"
      get-container="body"
    >
      <van-cascader
        v-model="checkCascaderValue"
        active-color="#055C9D"
        title="检查点"
        :field-names="checkFieldNames"
        :options="checkOptions"
        @close="isShowCheckPopup = false"
        @finish="onCheckFinish"
      />
    </van-popup>
  </custom-popup>
</template>

<script>
import CustomPopup from '@/components/Common/CustomPopup.vue'
import Counter from '@/components/Common/Counter.vue'
import FieldItem from "@/components/Common/FieldItem.vue";
import {mapActions, mapGetters} from 'vuex';
import {axios} from '@/utils/request';
import {postAction, getAction, putAction} from '@/api/manage';
import {cloneDeep, debounce} from 'lodash'
import imageUploader from "@/components/Common/ImageUploader.vue";
import {getRandomId, imgStrToList} from "@/utils/util";
import {ARTICLE_NO_COMPLETED} from "@/constants";
import moment from "moment";
import {isNative} from "@/config";
import {getCategoryList} from "@/utils/getDataByAxios";

export default {
  name: 'NewDefectPopup',
  components: {FieldItem, Counter, CustomPopup, imageUploader},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    defaultCheckItemObj: {
      type: Object,
      default() {
        return {}
      },
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        category: null,
        defectTypeCode: null,
        defectTypeName: null,
        defectCode: null,
        defectName: null,
        name: null,
        description: null,
        pictures: [],
        cri: 0,
        maj: 0,
        min: 0,
      },
      isEdit: false,
      isShowCategory: false,
      cascaderValue: '',
      options: [],
      fieldNames: {
        text: 'title',
        value: 'code',
        children: 'children',
      },
      selectedOptions: [],
      loading: false,
      articleInfo: {},

      isShowCheckPopup: false,
      checkCascaderValue: '',
      checkOptions: [],
      checkFieldNames: {
        text: "itemName",
        value: "itemName",
        children: "children",
      },
      checkPointList: [],
      customCheckPointList: []
    }
  },
  created() {
    const order = this.taskInfo.orderList.find(item => item.id === this.orderId)
    this.articleInfo = order.articleNoList.find(item => item.id === this.articleId)
    this.getCategoryList()
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    init(obj) {
      this.cascaderValue = ''
      if (obj) {
        this.form = {
          ...obj,
          checkName: obj.checkPointName,
          category: obj.defectTypeName ? `${obj.defectTypeName}/${obj.defectName}` : null,
          pictures: imgStrToList(obj.pictures),
        }
        this.isEdit = true
      } else {
        this.form = {
          id: getRandomId(),
          category: null,
          defectTypeCode: null,
          defectTypeName: null,
          defectCode: null,
          defectName: null,
          name: null,
          description: null,
          pictures: [],
          cri: 0,
          maj: 0,
          min: 0,
          checkName: "",
          articleCheckPointId: null,
        }
        this.isEdit = false
      }

      this.getCheckPointData()
      const checkPointOptions = this.checkPointList.map((i) => {
        // i.itemName = i.label + "-" + i.pointsName;
        i.itemName = i.pointsName;
        return i;
      });
      const customCheckPointListOptions = this.customCheckPointList.map((i) => {
        // i.itemName = i.inspectionItemName + "-" + i.checkPointName;
        i.itemName = i.checkPointName;
        i.custom = 1
        return i;
      });
      this.checkOptions = checkPointOptions.concat(customCheckPointListOptions)
      this.checkOptions.push({
        itemName: '无',
        checkPointsId: ''
      })
      if (this.defaultCheckItemObj?.id && !obj) {
        this.onCheckFinish({selectedOptions: [this.defaultCheckItemObj]})
      } else if(obj){
        this.checkCascaderValue = obj.checkPointName
      } else {
        this.onCheckFinish({
          selectedOptions: [
            {
              itemName: '无',
              checkPointsId: ''
            }
          ]
        })
      }
      this.cascaderValue = this.form.defectCode
    },
    handleInputBlur() {
      window.scroll(0, 0);
    },
    isFormValid() {
      if (!this.form.category) {
        this.Toast.fail('请选择缺陷类别')
        return false
      }
      // if (!this.form.name) {
      //   this.Toast.fail('请输入缺陷名称')
      //   return false
      // }
      // if (!this.form.description && !this.form.pictures.length) {
      //   this.Toast.fail('请输入缺陷描述或上传相关图片')
      //   return false
      // }
      if (!this.form.description) {
        this.Toast.fail('请输入缺陷描述')
        return false
      }
      if (!this.form.cri && !this.form.maj && !this.form.min) {
        this.Toast.fail('至少有一个缺陷统计')
        return false
      }
      if (this.form.pictures.find(item => !item.url)) {
        this.Toast.fail('请等待图片上传完成')
        return false
      }
      return true
    },
    getSubmitData() {
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      const formData = {
        ...this.form,
        pictures: this.form.pictures.map(item => item.url).join(',')
      }
      if (this.isEdit) {
        const index = article.inspectionList.defectList.findIndex(item => item.id === this.form.id)
        if (~index) {
          article.inspectionList.defectList[index] = formData
        }
      } else {
        article.inspectionList.defectList.push(formData)
      }
      return taskInfo
    },
    handleOk: debounce(async function () {
      const valid = this.isFormValid()
      if (valid) {
        const data = this.getSubmitData()
        this.loading = true
        const res = await this.saveTaskInfo(data)
        this.loading = false
        if (res.success) {
          this.$emit('on-ok')
        }
      }
    }, 500),
    onFinish({selectedOptions}) {
      this.isShowCategory = false;
      this.form.category = selectedOptions.map((option) => option.title).join('/');
      this.form.defectTypeCode = selectedOptions[0].code
      this.form.defectTypeName = selectedOptions[0].title
      this.form.defectCode = selectedOptions[1].code
      this.form.defectName = selectedOptions[1].title
    },
    onCheckFinish({selectedOptions}) {
      this.isShowCheckPopup = false;
      const item = selectedOptions[0]
      if (item.custom === 1) {
        // this.form.checkName = item.inspectionItemName + '-' + item.checkPointName
        this.form.checkName = item.checkPointName
        this.form.articleCheckPointId = item.id
        this.form.checkPointId = item.id
        // this.form.checkPointName = item.inspectionItemName + '-' + item.checkPointName
        this.form.checkPointName = item.checkPointName
      } else if (item.itemName !== '无') {
        // this.form.checkName = item.label + '-' + item.pointsName
        this.form.checkName = item.pointsName
        this.form.articleCheckPointId = item.id
        this.form.checkPointId = item.checkPointsId
        // this.form.checkPointName = item.label + '-' + item.pointsName
        this.form.checkPointName = item.pointsName
      } else if (item.itemName === '无') {
        this.form.checkName = '无'
        this.form.articleCheckPointId = ''
        this.form.checkPointId = ''
        this.form.checkPointName = '无'
      }
      this.checkCascaderValue = this.form.checkPointName
    },
    async getCategoryList() {
      let res = {}
      if (isNative) {
        try {
          // res = await window.nativeApp.getDictData('');
          res = await window.nativeApp.getDefectList(this.taskInfo.id, this.articleInfo.articleNoId);
          console.log('获取缺陷类别成功：', res)
        } catch (e) {
          console.log('获取缺陷类别失败：')
          console.error(e)
        }
      } else {
        res = await getCategoryList(this.articleInfo.articleNoId)
      }
      if (res.success) {
        const result = JSON.parse(res.result)
        this.options = result.map(item => {
          if (item.children?.length) {
            item.children = item.children.map(i => {
              delete i.children
              return i
            })
          }
          return item
        }).filter(item => item?.children?.length)
      }
    },
    getCheckPointData() {
      const taskInfo = cloneDeep(this.taskInfo)
      const orderList = taskInfo?.orderList || []
      const order = orderList.find(item => item.id === this.orderId)
      const articleNoList = order?.articleNoList || []
      const article = articleNoList.find(item => item.id === this.articleId)
      this.checkPointList = article.inspectionList?.checkPointList || []
      this.customCheckPointList = article.inspectionList?.customCheckPointList || []
    },
  }
}
</script>

<style scoped lang="less">
.new-defect::v-deep {
  .check-select {
    width: calc(50% - 10px);
  }

  .check-category {
    width: 100%;
  }

  .statistic-item {
    margin-bottom: 8px;
  }

  .btn-wrapper {
    margin-top: 22px;

    .btn {
      width: 100%;
    }
  }
}
</style>
