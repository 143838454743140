<template>
  <custom-popup
      class="remark-detail"
      :show="show"
      title="备注详情"
      @on-close="$emit('on-close')"
  >
    <div class="pop-content">
      <field-item label="检查点">
        <span v-if="form.id === 'remarks'">点数抽样</span>
        <span v-else-if="form.inspectItemName">{{form.checkPointName}}</span>
        <span v-else>无</span>
      </field-item>
      <field-item label="备注描述">
        <div class="value-box">
          <span>{{ form.description }}</span>
        </div>
      </field-item>
      <field-item label="备注图片">
        <van-uploader
          v-if="pictures.length"
          v-model="pictures"
          readonly
          :deletable="false"
          :show-upload="false"
          :preview-size="70"
          :upload-icon="require('@/assets/svg/icon-camera.svg')"
        />
        <span v-else>无</span>
      </field-item>
    </div>
    <div class="btn-wrapper space-between" :class="{'disabled': disabled}">
      <van-button v-show="form.id !== 'remarks'" class="btn" size="large" color="#055C9D" plain @click="$emit('on-del')">删除</van-button>
      <van-button type="primary" class="btn" size="large" color="#055C9D" @click="$emit('on-edit')">编辑</van-button>
    </div>
<!--    <div class="btn-wrapper space-between" :class="{'disabled': disabled}">-->
<!--      <van-button class="block-btn" color="#055C9D" block @click="$emit('on-close')">返回</van-button>-->
<!--    </div>-->
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import FieldItem from "@/components/Common/FieldItem.vue";
import {imgStrToList} from "@/utils/util";

export default {
  name: "RemarkDetailPopup",
  components: {FieldItem, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {},
      disabled: false,
      pictures: []
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val){
        if(val){
          this.pictures = imgStrToList(this.form.pictures)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.remark-detail {
  .pop-content {
    min-height: 45vh;
  }
  .value-box{
    display: flex;
    flex-direction: column;
    span{
      margin-bottom: 5px;
    }
  }
  .btn-wrapper {
    margin-top: 20px;

    .btn, .primary-btn {
      width: 100%;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }

    &.disabled {
      visibility: hidden;
    }
  }
}
</style>
