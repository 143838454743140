<template>
  <div>
    <div v-if="relateDefectList && relateDefectList.length" class="form-item defect-form-item">
      <div class="label">缺陷明细：</div>
      <div class="content-box">
        <div v-for="(item, index) in relateDefectList" :key="item.id">
          <div class="info">
            <div class="name">
              {{ index + 1 }}. 【
              <span>CRI {{ item.cri }}</span>
              <span>MAJ {{ item.maj }}</span>
              <span style="margin-right: 0">MIN {{ item.min }}</span>
              】
              <span style="margin-left: 8px">{{ item.description }}</span>
            </div>
            <div class="desc">
              缺陷类别：{{ item.defectTypeName }}/{{ item.defectName }}
            </div>
            <div class="desc">
              关联检查点：{{ item.checkPointName }}
            </div>
            <div v-if="item.pictures" class="picture-wrap">
              <image-uploader
                v-model="item.picturesList"
                :deletable="false"
                :show-upload="false"
              ></image-uploader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="relateRemarkList && relateRemarkList.length" class="form-item remark-form-item">
      <div class="label">备注明细：</div>
      <div class="content-box">
        <div v-for="(item, index) in relateRemarkList" :key="item.id">
          <div class="info">
            <div class="name">{{ item.description }}</div>
            <div class="desc">
              <span v-if="item.inspectItemName">
                关联检查点：{{ item.checkPointName }}
              </span>
            </div>
            <div v-if="item.pictures" class="picture-wrap">
              <image-uploader
                v-model="item.picturesList"
                :deletable="false"
                :show-upload="false"
              ></image-uploader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageUploader from "@/components/Common/ImageUploader.vue";

export default {
  name: "CheckDefectRemarkDetail",
  components: {imageUploader},
  props: {
    articleCheckPointId: {
      type: String,
      default: ''
    },
    defectList: {
      type: Array,
      default() {
        return []
      },
    },
    remarkList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    relateRemarkList() {
      return this.remarkList.filter(item => item.articleCheckPointId === this.articleCheckPointId)
    },
    relateDefectList() {
      return this.defectList.filter(item => item.articleCheckPointId === this.articleCheckPointId)
    },
  },
}
</script>


<style lang="less" scoped>
.form-item {
  display: flex;
  align-items: flex-start;

  &.remark-form-item {
    margin-top: 20px;
  }

  &.defect-form-item {
    margin-top: 8px;
  }

  .label {
    width: 80px;
    margin: 0 4px;
    font-size: 12px;
    color: #AAB3BA;
    flex-shrink: 0;
  }

  .info {
    .name {
      font-size: 14px;
      color: #4B4E51;
      margin-bottom: 16px;

      span {
        margin-right: 8px;
      }
    }

    .desc {
      font-size: 12px;
      color: #797C80;
      margin-bottom: 10px;
    }

    .picture-wrap {

      img {
        width: 80px;
        height: 60px;
        object-fit: cover;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>